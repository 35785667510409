export interface CampaignsInterface {
	CampaignId?: string;
	Id: string;
	Name: string;
	BudgetAmount: number;
	Impression: number;
	Engagements: number;
	CampaignBudgetType: string;
	CampaignObjective: string;
	Currency: string;
	Status: string;
	CreatedByFirstName: string;
	CreatedById: number;
	CreatedByLastName: string;
	UpdatedAt: string;
	UpdatedByFirstName: string;
	UpdatedById: number;
	UpdatedByLastName: string;
	InfluencerId?: string;
}

export enum FilterTypeEnum {
	LOCATIONS = <any>'Location',
	STATE = <any>'State',
	CITY = <any>'City',
	LANGUAGES = <any>'Language',
	GENDER = <any>'Gender',
	TIER = <any>'Tier',
	HASHTAGS = <any>'Hashtag',
	CATEGORIES = <any>'Interest',
	HASEMAIL = <any>'Has Email',
	AGE = <any>'Age',
	IG = <any>'IG',
	TK = <any>'TK',
	YT = <any>'YT',
	AUGENDER = <any>'Audience Gender',
	AULOCATION = <any>'Audience Location',
	AUAGE = <any>'Audience Age',
	AU_AGE = <any>'Audience Age',
	AULANGUAGE = <any>'Audience Language',
	AUINTERESTS = <any>'Audience Interests',
	FLW = <any>'Followers',
	// MINFOLLOWERS = <any>'Followers',
	// MAXFOLLOWERS = <any>'Followers',
	ER = <any>'Engagement Rate',
	EPP = <any>'Average Interactions',
	// MINENGAGEMENTS = <any>'Average Interactions',
	// MAXENGAGEMENTS = <any>'Average Interactions',
	BK = <any>'Bio Keyword',
	IV = <any>'Is Verified',
	AT = <any>'Account Type',
	VW = <any>'Overall Views',
	AVPP = <any>'Average Views Per Post',
	PF = <any>'Post Frequency'
}

export interface campaignObjective {
	id: number;
	name: string;
}
export interface commonDataModel {
	id?: number;
	name: string;
	region?: string;
	countryName?: string;
	type?: string;
}
export interface fieldType {
	id: string;
	name: string;
	value?: string;
	type?: string;
}
export enum FilterType {
	INFLUENCER = 'influencer',
	AUDIENCE = 'audience'
}
export interface campaignCurrency {
	id: number;
	name: string;
	iso: string;
	symbol: string;
}

export interface budgetType {
	id: number;
	name: string;
}
export interface campaignForm {
	name: string;
	budget_amount: number;
	currency: number;
	currency_id: number;
	budget_type: number;
	description: string;
	campaign_link: string;
	tracking_link: string;
}

export interface categories {
	categories: string[];
}
