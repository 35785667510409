import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, of } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
	selector: 'app-select-drp-w-input',
	templateUrl: './select-drp-w-input.component.html',
	styleUrls: ['./select-drp-w-input.component.scss']
})
export class SelectDrpWInputComponent implements OnInit {
	@ViewChild('autoTrigger', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
	@ViewChild('multiChipInput') multiChipInput;
	@Input() list: any[] = [];
	@Input() width: string = '100%';
	@Input() placeholder: string = 'Search..';
	@Input() inputControl: UntypedFormControl;
	@Input() label?: string;
	@Input('disabled') public disabled: boolean = false;
	@Output() emitSelection: EventEmitter<any> = new EventEmitter<any>();
	@Output() emitDeselection: EventEmitter<any> = new EventEmitter<any>();
	public filteredList: Observable<any[]>;
	public unsubscriber$ = new Subject<void>();

	constructor() {}

	ngOnInit(): void {
		this.filteredList = this.inputControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.list.slice();
			}),
			takeUntil(this.unsubscriber$)
		);
	}

	private _filter(name: string): any[] {
		const filterValue = name.toLowerCase();
		return this.list.filter(option => option.name.toLowerCase().includes(filterValue));
	}

	ngAfterViewInit() {
		window.addEventListener('scroll', this.scrollEvent, true);
	}

	scrollEvent = (event: any): void => {
		if (this.autoComplete && this.autoComplete?.panelOpen) this.autoComplete.updatePosition();
	};

	getOptionText(option) {
		return option?.name;
	}

	public onOptionSelected(event): void {
		this.filteredList = this.inputControl.valueChanges.pipe(
			startWith(''),
			map(value => {
				const name = typeof value === 'string' ? value : value?.name;
				return name ? this._filter(name as string) : this.list.slice();
			}),
			takeUntil(this.unsubscriber$)
		);
		this.emitSelection.emit(event.option.value);
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
