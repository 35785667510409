import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
	selector: 'app-website-input',
	templateUrl: './website-input.component.html',
	styleUrls: ['./website-input.component.scss']
})
export class WebsiteInputComponent implements OnInit {
	@Input() public inputControl: UntypedFormControl;

	constructor() {}

	ngOnInit(): void {}
}
