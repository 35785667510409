import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-generic-input-dialog',
	templateUrl: './generic-input-dialog.component.html',
	styleUrls: ['./generic-input-dialog.component.scss']
})
export class GenericInputDialogComponent implements OnInit {
	public nameControl: UntypedFormControl;
	public suffixText?: string;

	constructor(
		private toastNotificationService: ToastNotificationService,
		public dialogRef: MatDialogRef<GenericInputDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.suffixText = data?.suffixText;
		if (this.data?.control) {
			this.nameControl = this.data.control;
		} else {
			this.nameControl = new UntypedFormControl();
		}
	}

	ngOnInit(): void {}

	public primaryAction(): void {
		this.dialogRef.close({ name: this.nameControl.value });
	}
}
