export enum CampaignRewardTypeEnum {
	MONETARY_COMMISSION = 'monetary_commission',
	FREE_SUBSCRIPTION_MONTHS = 'free_subscription_months',
	POINTS = 'points',
	CREDITS = 'credits',
	DISCOUNT_COUPON = 'discount_coupon',
	DISCOUNT_COUPON_PERCENT = 'discount_coupon_percent'
}

export enum CampaignRewardUnitEnum {
	PERCENT = 'percent',
	FLAT = 'flat'
}

export enum CampaignRewardCommissionLengthTypeEnum {
	CUSTOMER_LIFETIME = 'customer_lifetime',
	TIME_SPAN = 'time_span',
	COUNT = 'count'
}

export interface CampaignRewardModel {
	id: number;
	type: CampaignRewardTypeEnum;
	name: string;
	value: number;
	unit: CampaignRewardUnitEnum;
	commision_lenght_type?: CampaignRewardCommissionLengthTypeEnum;
	commission_length_value?: number;
	coupon?: string;
}
