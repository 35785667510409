import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthenticationState, getAccountConnectionState, getIsFirstTimeCustomer } from './authentication.reducer';
import { AccountConnectionState, AuthenticationActionType, LogIn, LogInFailure, LogInSuccess, ShowOtpInput } from './authentication.action';
import { catchError, concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { EMPTY, of } from 'rxjs';

import { TokenService } from '../../_services/token.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { UserState } from '../../shared/state/user/user.reducer';
import { SetUserDetails } from '../../shared/state/user/user.actions';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { AppStateSlices } from '../../state/app.state';
import { ErrorsLoggingService } from '../../_services/errors-logging/errors-logging.service';
import { AuthenticationEffectsEnum } from './effects.enum';
import { UserRejectedReason } from '../../_services/user/user-rejected-reason.enum';
import { MiscellaneousPermissions } from '../../shared/permisions/enums/miscellaneous-permissions';
import { Modules } from '../../shared/permisions/enums/modules';
import { GenericPopupComponent } from 'src/app/shared/pop-ups/generic-popup/generic-popup.component';
import { PopUpInterface } from 'src/app/shared/models/generic-popup-interface';
import { MatDialog } from '@angular/material/dialog';
import { GoogleService } from 'src/app/_services/google/google.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BusinessOwnerService } from 'src/app/_services/facebook-accounts/business-owner.service';
import { MyPlanService } from 'src/app/user-management/new-sprint/components/my-plan/my-plan.service';
import { environment } from 'src/environments/environment';
import { UserPaymentSubscriptionCodesEnum } from 'src/app/shared/models/plan-limits.model';
import { PaymentLinkStatusEnum } from 'src/app/shared-components/models/payment-status.enum';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable()
export class AuthenticationEffects {
	private launchHandler: NodeJS.Timeout;
	public priceId: string = environment.DEFAULT_PRICE_ID;

	public constructor(
		private router: Router,
		private actions$: Actions,
		private userServiceApi: UserServiceApi,
		private googleService: GoogleService,
		private tokenService: TokenService,
		private authenticationService: AuthenticationService,
		private toastNotificationService: ToastNotificationService,
		private authStore: Store<AuthenticationState>,
		private userStore: Store<UserState>,
		private errorsLoggingService: ErrorsLoggingService,
		private matDialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private sharedService: SharedService,
		public _myPlan: MyPlanService
	) {}

	logIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LogIn>(AuthenticationActionType.LogIn),
			map(action => action.payload),
			mergeMap(payload =>
				payload
					? this.userServiceApi.authenticate(payload.email, payload.password, payload.code).pipe(
							map((result: any) => {
								if (typeof result === 'string') return new LogInSuccess(result);
								return new LogInFailure(result?.msg);
							}),
							catchError(error => {
								this.errorsLoggingService.logEffectError(AppStateSlices.Authentication, AuthenticationEffectsEnum.logIn, [payload], error);
								return of(new LogInFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
							})
					  )
					: this.userServiceApi.refreshUserToken().pipe(
							map(result => new LogInSuccess(result)),
							catchError(error => {
								this.errorsLoggingService.logEffectError(AppStateSlices.Authentication, AuthenticationEffectsEnum.logIn, [payload], error);
								return of(new LogInFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
							})
					  )
			)
		)
	);

	logInSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<LogInSuccess>(AuthenticationActionType.LogInSuccess),
				map(action => action),
				mergeMap(payload => this.runOp(payload))
			),
		{ dispatch: false }
	);

	private runOp(payload): any {
		this.authenticationService.signInLoaderStateChange(false);
		if (payload.payload === 'True') {
			this.authStore.dispatch(new ShowOtpInput(true));
			return;
		}

		if (payload.payload === 'False') {
			this.authenticationService.logout();
			this.toastNotificationService.sendErrorToast(
				'There was an issue with sending out a message. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
			);
			return;
		}
		const token = payload.payload;
		localStorage.setItem(StorageKey.token, token);
		localStorage.setItem(StorageKey.firstTimeDiscovery, 'true');

		this.setFirstTimeTokens();

		localStorage.setItem(StorageKey.first_time_lolly_user, 'true');
		localStorage.setItem(StorageKey.first_time_login_lists, 'true');
		const decodedJwtIo = this.tokenService.decodeToken(token);
		const userDetails = this.authenticationService.getUserDetailsFromJwt(decodedJwtIo);
		if (userDetails?.RejectedReason === UserRejectedReason.FrontOfficeUserWithZeroAdAccounts) {
			this.authenticationService.logout();
			this.toastNotificationService.sendErrorToast(
				'There was an issue with syncing your data. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
			);
			return;
		}
		localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(decodedJwtIo));
		this.userStore.dispatch(new SetUserDetails(userDetails));

		// console.log('account state', userDetails.AccountState);
		switch (userDetails.AccountState) {
			case UserStateEnum.Active:
				localStorage.removeItem('details');
				let hasBackOfficePermission = false;
				let backOfficeDefaultUrl = 'back-office';
				userDetails.Permissions.filter(module => module.module === Modules.Miscellaneous).forEach(module => {
					module.permissions.forEach(permission => {
						if (
							permission === MiscellaneousPermissions.IsAdmin ||
							permission === MiscellaneousPermissions.IsAccountManagerAdmin ||
							permission === MiscellaneousPermissions.IsSales ||
							permission === MiscellaneousPermissions.IsSalesAdmin ||
							permission === MiscellaneousPermissions.IsAccountManager
						) {
							if (permission === MiscellaneousPermissions.IsSales) {
								backOfficeDefaultUrl = 'back-office/role';
							}
							hasBackOfficePermission = true;
						}
					});
				});

				const bigCommerceReload = this.authenticationService.bigCommerceAction$.value;

				if (hasBackOfficePermission) {
					this.router.navigate([backOfficeDefaultUrl]);
				} else if (bigCommerceReload) {
					this.bigCommerceReloadAction();
					this.authenticationService.bigCommerceAction$.next(null);
				} else {
					this.authStore.pipe(select(getIsFirstTimeCustomer), take(1)).subscribe(isFirstTimeCustomer => {
						const checkSignUpGetStarted = this.backOfficeService.signUpGetStartedActive$.value;
						if (checkSignUpGetStarted !== 'active') {
							this.router.navigate(['/']);
						} else if (checkSignUpGetStarted === 'active') {
							this.backOfficeService.signUpGetStartedActive$.next('running');
						}
					});
				}
				break;
			case UserStateEnum.NoBusinessOwner:
				this.router.navigate(['/']);
				break;
			case UserStateEnum.Removed:
			case UserStateEnum.InTransition:
				// this.router.navigate(['authentication/payment-pending']);
				this.getPaymentLink();
				break;
			// case UserStateEnum.NoCreditCard:
			// 	this.router.navigate(['authentication/payment']);
			// 	break;
			case UserStateEnum.FreemiumExpiredNoCreditCard:
				this.router.navigate(['authentication/trial-end']);
				break;
			case UserStateEnum.FreeTrialExpiredNoCreditCard:
				this.router.navigate(['authentication/payment']);
				break;
			case UserStateEnum.BillPendingRestrictedAccess:
				// no definition
				this.router.navigate(['user-management/billing-and-payment']);
				break;
			case UserStateEnum.BillPendingUnrestrictedAccess:
				this.router.navigate(['/']);
				break;
			case UserStateEnum.Shoppify:
				this.router.navigate(['connect-to-shopify']);
				break;
			case UserStateEnum.fromShopify:
				this.router.navigate(['authentication/billing-plans']);
				break;
			default:
				this.toastNotificationService.sendErrorToast('Something went wrong! Please log in again');
				localStorage.removeItem('details');
				this.authenticationService.logout();
		}
	}

	logInFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType<LogInFailure>(AuthenticationActionType.LogInFailure),
				tap(() => {
					this.authenticationService.signInLoaderStateChange(false);
				})
			),
		{ dispatch: false }
	);

	private setFirstTimeTokens(): void {
		localStorage.setItem(StorageKey.firstTimeCampaign, 'true');
		localStorage.setItem(StorageKey.firstTimeCRM, 'true');
	}

	public getPaymentLink(): void {
		const token = localStorage.getItem(StorageKey.token);
		this.sharedService
			.getUserPaymentStatus()
			.pipe(take(1))
			.subscribe(
				res => {
					if (res.status == PaymentLinkStatusEnum.SUCCESS) {
						const paymentLink = res.data;
						window.open(paymentLink, '_self');
					} else {
						this.router.navigate(['/authentication/payment'], { queryParams: { token, price_id: this.priceId } });
					}
				},
				err => {}
			);
	}

	private bigCommerceReloadAction(): void {
		this.launchHandler = setTimeout(() => {
			this.router.navigate(['accounts']);
			window.open(window.location.origin + '/accounts', '_blank').focus();
			this.matDialog.closeAll();
		}, 10000);
		this.matDialog
			.open<GenericPopupComponent, PopUpInterface>(GenericPopupComponent, {
				data: {
					message: 'Your Filed dashboard will launch in the next tab in 10 seconds, please allow popup from www.filed.com',
					actionAnswer: 'Launch now',
					noActionAnswer: '',
					header: 'Setup Installation Successful',
					image: '/assets/icons/success.svg'
				},
				panelClass: ['generic-popup'],
				disableClose: true
			})
			.afterClosed()
			.subscribe(clicked => {
				if (clicked) {
					clearTimeout(this.launchHandler);
					this.router.navigate(['accounts']);
					window.open(window.location.origin + '/accounts', '_blank').focus();
					this.matDialog.closeAll();
				}
			});
	}
}
