import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import {
	CatalogCreationModel,
	CatalogDeletionModel,
	CatalogToImportModel,
	EcommerceGenericModel,
	EcommerceGetCatalogResponse,
	EcommerceGetConnectedPlatformResponse,
	ImportCatalogsResponse
} from '../models/ecommerce-catalog.model';
import { EcommercePlatformsEnum } from '../models/ecommerce.enum';
import { GetProductsByCatalogResponse, GetProductSetsResponse, ImportProductManuallyPayload } from '../models/ecommerce-product.model';

@Injectable({
	providedIn: 'root'
})
export class EcommerceService {
	constructor(private http: HttpClient) {}

	public getConnectedPlatform(): Observable<EcommerceGetConnectedPlatformResponse> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/manage/platform`);
		return this.http.get<EcommerceGetConnectedPlatformResponse>(url.href);
	}

	public getCategoriesList(): Observable<{ data: EcommerceGenericModel[] }> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/manage/categories`);
		return this.http.get<{ data: EcommerceGenericModel[] }>(url.href);
	}

	public connectShopify(shop: string): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/install?shop=${shop}`);
		return this.http.get<any>(url.href);
	}

	public shopifyCallback(queryParams): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}oauth/shopify/callback`);
		return this.http.get<any>(url.href, { params: queryParams });
	}

	public connectBigcommerce(email): Observable<any> {
		console.log(`start connectBigcommerce with email = ${email}`);
		let url = new URL(`${BaseApiUrl.SocialMessengerPython}oauth/bigcommerce/preinstall?email=${email}`);
		return this.http.get<any>(url.href);
	}

	public connectEcommercePlatform(platformAlias): Observable<any> {
		let url = new URL(`${BaseApiUrl.SocialMessengerPython}oauth/${platformAlias}/preinstall?shop=lollytestingstore`);
		return this.http.get<any>(url.href);
	}

	public fetchCatalogsFromEcommercePlatform(platform: EcommercePlatformsEnum): Observable<ImportCatalogsResponse> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/manage/import?platform=${platform}`);
		return this.http.get<ImportCatalogsResponse>(url.href);
	}

	public importCatalogs(platform: EcommercePlatformsEnum, payload: CatalogToImportModel[]): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/catalogs/import?platform=${platform}`);
		return this.http.post<any>(url.href, payload);
	}

	public getAllCatalogs(queryParams: HttpParams): Observable<EcommerceGetCatalogResponse> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/catalogs`);
		return this.http.get<EcommerceGetCatalogResponse>(url.href, { params: queryParams });
	}

	public getTotalCatalogsLength(): Observable<{ no_of_catalogs: number }> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/catalogs/total`);
		return this.http.get<{ no_of_catalogs: number }>(url.href);
	}

	public saveProductManually(payload: ImportProductManuallyPayload): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/products/manually`);
		return this.http.post<any>(url.href, payload);
	}

	public saveProductByCSV(formData: FormData): Observable<{ message: string; no_of_products_added?: number }> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/products/csv`);
		return this.http.post<{ message: string; no_of_products_added?: number }>(url.href, formData);
	}

	public deleteCatalogs(payload: CatalogDeletionModel): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/catalogs`);
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: payload
		};
		return this.http.delete<any>(url.href, options);
	}

	public deleteEcommerceUserIntegration(): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/manage/integration`);
		return this.http.delete<any>(url.href);
	}

	// * PRODUCTS APIs * //

	public getProductsOfACatalog(params: HttpParams): Observable<GetProductsByCatalogResponse> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/products`);
		return this.http.get<GetProductsByCatalogResponse>(url.href, { params: params });
	}

	public deleteProductsByIds(payload): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/products`);
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: payload
		};
		return this.http.delete<any>(url.href, options);
	}

	// * PRODUCT SET APIs * //

	public createProductSet(payload): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/product_sets`);
		return this.http.post<any>(url.href, payload);
	}

	public getProductSets(queryParams: HttpParams): Observable<GetProductSetsResponse> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/product_sets`);
		return this.http.get<GetProductSetsResponse>(url.href, { params: queryParams });
	}

	public deleteProductSets(payload): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/product_sets`);
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: payload
		};
		return this.http.delete<any>(url.href, options);
	}

	public deleteProductFromSets(payload): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}ecommerce/product_sets/products`);
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: payload
		};
		return this.http.delete<any>(url.href, options);
	}
}
