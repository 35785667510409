import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { allCountries } from 'src/app/shared/storage/countries-data-store';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { NewUserModel } from '../../../models/new-user-model';
import { CustomValidator } from 'src/app/shared/form-input/input-password/custom-validator';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/_services/token.service';
import { URL_REGEXP, getFormData, updateDecodedToken, updateToken } from 'src/app/shared/utils';
import { LollyLoadingDialogService } from 'src/app/shared-components/lolly-loading-dialog/lolly-loading-dialog.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SavePasswordRequestModel } from './model/profile.model';

export interface Countries {
	code: string;
	code3: string;
	name: string;
	number: string;
	phone: string;
}

@Component({
	selector: 'app-my-profile',
	templateUrl: './new-my-profile.component.html',
	styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, OnDestroy {
	@ViewChild('imgFileSelect') imgFileSelect: ElementRef;
	public userForm: UntypedFormGroup;
	public contactForm: UntypedFormGroup;
	public securityForm: UntypedFormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public isEdit: boolean = false;
	public isEdit2: boolean = false;
	public userId: string;
	public userDetail = new NewUserModel();
	public userRoles: any;
	public userProfile: any;
	public isEditActive: boolean = false;
	public isEditActive2: boolean = false;
	public isEditActive3: boolean = false;
	public validNumber: boolean = true;
	public uploadMoreCount: number = 0;
	public SUPPORTED_IMG_FILES = ['image/jpeg', 'image/jpg', 'image/png'];

	public countries: Countries[] = allCountries;
	public allCountryCodes: string[] = this.countries.map(c => c.name + ' +' + c.phone);
	public filteredCountries2: string[] = this.allCountryCodes;

	public accessLevel = [
		{ label: 'Standard', value: 'Standard' },
		{ label: 'View only', value: 'View only' }
	];

	public decodeJwtToken: UserDetailsInterface;

	public loading: boolean = false;

	private filedId: number;

	constructor(
		public dialog: MatDialog,
		private router: Router,
		public lollyLoadingDialogService: LollyLoadingDialogService,
		public sharedService: SharedService,
		public backOfficeService: BackOfficeService,
		private formBuilder: UntypedFormBuilder,
		private toastNotification: ToastNotificationService,
		private authenticationService: AuthenticationService,
		private tokenService: TokenService
	) {}

	public ngOnInit(): void {
		this.createForm();
		this.fetchStore();
	}

	public handleFilter2(event: string): void {
		if (!event) this.filteredCountries2 = this.allCountryCodes;
		this.filteredCountries2 = this.allCountryCodes.filter(e => e.toLowerCase().includes(event.toLowerCase()));
	}

	public mobileValueChange(event): void {
		this.contactForm.get('phone_code').patchValue(event?.split(' ').pop());
	}

	public numberChange(val: string): void {
		/^([\s0-9-]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(val) ? (this.validNumber = true) : (this.validNumber = false);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public editName(): void {
		this.isEditActive = !this.isEditActive;
	}

	public editName2(): void {
		this.isEditActive2 = !this.isEditActive2;
	}

	public editName3(): void {
		this.isEditActive3 = !this.isEditActive3;
		this.securityForm.reset();
	}

	public logout(url): void {
		// this.profileOpen = false;
		// this.authenticationService.expandMenu$.next(false);
		this.authenticationService.logoutWithRedirect(url);
	}

	public onForgotPasswordClick(): void {
		this.logout('/authentication/reset-password-with-email');
	}

	private onError(err): void {
		const title = 'Oops, something went wrong!';
		const msg = 'Please try again!';

		this.toastNotification.sendCustomToast(msg, ToastNotificationType.Error, 2000, title);
	}

	private fetchStore(): void {
		this.loading = true;
		this.backOfficeService
			.getProfileDetails(localStorage.getItem(StorageKey.token))
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.userForm.patchValue({
						current_company: res?.company,
						current_company_website: res?.company_website,
						current_company_logo: res?.company_logo,
						company: res?.company,
						company_website: res?.company_website,
						company_logo: res?.company_logo,
						firstname: res?.first_name,
						lastname: res?.last_name,
						accessLevel: res?.position
					});
					this.contactForm.patchValue({
						current_phone: res?.phone_number?.split(' ')?.slice(1)?.join(' '),
						current_email: res?.email,
						phone: res?.phone_number?.split(' ')?.slice(1)?.join(' '),
						email: res?.email,
						phone_code: res?.phone_number?.split(' ')[0],
						current_phone_code: res?.phone_number?.split(' ')[0]
					});
				},
				err => this.onError
			)
			.add(() => (this.loading = false));
	}

	private createForm(): void {
		this.userForm = this.formBuilder.group({
			firstname: new UntypedFormControl('', Validators.required),
			lastname: new UntypedFormControl('', Validators.required),

			// Current Value Controls
			current_company: new UntypedFormControl(''),
			current_company_website: new UntypedFormControl(''),
			current_company_logo: new UntypedFormControl(''),

			// Editable Value Controls
			company: new UntypedFormControl(''),
			company_website: new UntypedFormControl(''),
			company_logo: new UntypedFormControl(''),
			accessLevel: new UntypedFormControl('', Validators.required)
		});

		this.contactForm = this.formBuilder.group({
			// Current Value Controls
			current_email: new UntypedFormControl(null, [
				Validators.required,
				Validators.email,
				Validators.pattern(
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
			]),
			current_phone: new UntypedFormControl(null, [Validators.minLength(8)]),
			current_phone_code: new UntypedFormControl(null),

			// Editable Value Controls
			email: new UntypedFormControl(null, [
				Validators.required,
				Validators.email,
				Validators.pattern(
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
			]),
			phone: new UntypedFormControl(null, [Validators.minLength(8)]),
			phone_code: new UntypedFormControl(null)
		});

		this.securityForm = this.formBuilder.group(
			{
				current: new UntypedFormControl('', Validators.required),
				password: new UntypedFormControl('', [
					Validators.required,
					Validators.minLength(8),
					// check whether the entered password has a lower case letter
					CustomValidator.patternValidator(/[a-z]/, {
						hasSmallCase: true
					}),
					// check whether the entered password has upper case letter
					CustomValidator.patternValidator(/[A-Z]/, {
						hasCapitalCase: true
					}),
					// CustomValidator.patternValidator(/\d/, {
					// 	hasNumber: true
					// }),
					// // check whether the entered password has a special character
					// CustomValidator.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
					// 	hasSpecialCharacters: true
					// }),
					// check whether the entered password has a number or special character
					CustomValidator.patternValidator(/\d|[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
						hasSpecialCharacters: true
					})
				]),
				confirmPassword: new UntypedFormControl('', [Validators.required])
			},
			{
				// check whether our password and confirm password match
				validator: CustomValidator.passwordMatchValidator
			}
		);
	}

	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.userForm.get('accessLevel').setValue(selected);
		}
	}

	public editDetails(cb?): void {
		const payload: any = {};
		const formData = new FormData();
		let phoneNumber = `${this.contactForm.get('phone_code').value} ${this.contactForm.get('phone').value}`;

		// if (this.contactForm.get('email').dirty) {
		// 	if (!this.contactForm.get('email').valid) return this.toastNotification.sendWarningToast('Please enter a proper Email');
		// 	payload.email = this.contactForm.get('email').value;
		// }

		if (!this.contactForm.get('phone').valid) return this.toastNotification.sendWarningToast('Please enter a proper Phone Number');
		payload.phone_number = phoneNumber;

		// if (this.userForm.get('firstname').dirty) {
		// 	if (!this.userForm.get('firstname').valid) return this.toastNotification.sendWarningToast('Please enter a valid Name');
		// 	payload.first_name = this.userForm.get('firstname').value;
		// }

		// if (this.userForm.get('lastname').dirty) {
		// 	if (!this.userForm.get('lastname').valid) return this.toastNotification.sendWarningToast('Please enter a valid Last Name');
		// 	payload.last_name = this.userForm.get('lastname').value;
		// }

		if (this.userForm.get('company').dirty) {
			if (!this.userForm.get('company').valid) return this.toastNotification.sendWarningToast('Please enter a valid Name');
			payload.company = this.userForm.get('company').value;
		}

		if (this.userForm.get('company_website').dirty) {
			const website = this.userForm.get('company_website')?.value;
			if (!website || website.trim() == '') {
				return this.toastNotification.sendWarningToast('Please enter a valid website');
			}
			if (!URL_REGEXP.test(website)) {
				return this.toastNotification.sendWarningToast('Please enter a valid website');
			}
			payload.company_website = this.userForm.get('company_website').value;
		}

		if (!this.userForm.get('company_logo').valid) return this.toastNotification.sendWarningToast('Please upload a valid logo');
		payload.company_logo = this.userForm.get('company_logo').value;

		for (let key in payload) formData.append(key, payload[key]);

		if (Object.keys(payload).length === 0) {
			this.toastNotification.sendWarningToast('Please edit the details to Save');
			return;
		}

		this.backOfficeService
			.updateProfileDetails(formData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				if (res.status) {
					// this.toastNotification.sendSuccessToast('New Details were Updated');
					this.updateToken();

					if (this.isEditActive) {
						this.isEditActive = false;
						this.updateCurrentBasicControls();
					}
					if (this.isEditActive2) {
						this.isEditActive2 = false;
						this.updateCurrentAddressControls();
					}
					if (cb) cb();
				}
			});
	}

	public updateCurrentBasicControls(): void {
		const newCompany = this.userForm.get('company').value;
		const newCompanyWebsite = this.userForm.get('company_website').value;
		const newCompanyLogo = this.userForm.get('company_logo').value;
		this.userForm.get('current_company').patchValue(newCompany);
		this.userForm.get('current_company_website').patchValue(newCompanyWebsite);
		this.userForm.get('current_company_logo').patchValue(newCompanyLogo);
	}

	public updateCurrentAddressControls(): void {
		const newEmail = this.contactForm.get('email').value;
		const newPhone = this.contactForm.get('phone').value;
		const newPhoneCode = this.contactForm.get('phone_code').value;
		this.contactForm.get('current_email').patchValue(newEmail);
		this.contactForm.get('current_phone').patchValue(newPhone);
		this.contactForm.get('current_phone_code').patchValue(newPhoneCode);
	}

	private updateToken(): void {
		let token = localStorage.getItem(StorageKey.token);

		this.tokenService
			.refreshToken(token)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				if (res) {
					updateToken(res);
					updateDecodedToken({ company_name: this.userForm.value.company });
				}
			});
	}

	public saveBasicDetails(): void {
		this.editDetails(() => {
			this.toastNotification.sendSuccessToast('Your brand name is successfully saved!');
		});
	}

	public saveAddressDetails(): void {
		this.editDetails(() => {
			this.toastNotification.sendSuccessToast('Your phone number is successfully changed!');
		});
	}

	public savePassword(): void {
		const { current, password } = this.securityForm.value;
		const payload: SavePasswordRequestModel = {
			old_password: current,
			new_password: password
		};

		this.backOfficeService
			.updatePassword(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.toastNotification.sendSuccessToast('Password updated successfully!');
					if (this.isEditActive3) this.isEditActive3 = false;
				},
				err => {
					if (err.status === 400) {
						const title = 'Oops, something went wrong!';
						const msg = err.error.detail;
						this.toastNotification.sendCustomToast(msg, ToastNotificationType.Error, 2000, title);
					} else this.onError(err);
				}
			);
	}

	public editProfile(): void {
		this.isEdit = true;
		this.userForm.controls['firstname'].enable();
		this.userForm.controls['lastname'].enable();
		this.userForm.get('accessLevel').enable();
		this.userForm.get('company').enable();
		this.userForm.get('company_website').enable();
		this.userForm.get('company_logo').enable();
	}

	public editProfile2(): void {
		this.isEdit2 = true;
		this.contactForm.controls['email'].enable();
		this.contactForm.get('phone').enable();
	}

	public toggleEdit(): void {
		this.isEditActive = !this.isEditActive;
		this.resetEditableBasicControls();
	}

	public toggleEdit2(): void {
		this.isEditActive2 = !this.isEditActive2;
		this.resetEditableAddressControls();
	}

	public resetEditableBasicControls(): void {
		const currentCompany = this.userForm.get('current_company').value;
		const currentCompanyWebsite = this.userForm.get('current_company_website').value;
		const currentCompanyLogo = this.userForm.get('current_company_logo').value;
		this.userForm.get('company').patchValue(currentCompany);
		this.userForm.get('company').markAsPristine();
		this.userForm.get('company_website').patchValue(currentCompanyWebsite);
		this.userForm.get('company_website').markAsPristine();
		this.userForm.get('company_logo').patchValue(currentCompanyLogo);
		this.userForm.get('company_logo').markAsPristine();
	}

	public resetEditableAddressControls(): void {
		const currentEmail = this.contactForm.get('current_email').value;
		const currentPhone = this.contactForm.get('current_phone').value;
		const currentPhoneCode = this.contactForm.get('current_phone_code').value;
		this.contactForm.get('email').patchValue(currentEmail);
		this.contactForm.get('email').markAsPristine();
		this.contactForm.get('phone').patchValue(currentPhone);
		this.contactForm.get('phone').markAsPristine();
		this.contactForm.get('phone_code').patchValue(currentPhoneCode);
		this.contactForm.get('phone_code').markAsPristine();
	}

	//! UPLOAD FILE PART

	public openAddImgFilesDialog() {
		const e = this.imgFileSelect.nativeElement;
		e.addEventListener('change', this.onImgFileEleChange.bind(this));
		this.uploadMoreCount = 0;
		e.click();
	}

	public onImgFileEleChange(event: any): void {
		if (this.uploadMoreCount == 1) {
			return;
		}
		this.uploadMoreCount = 1;
		const file = event.target.files[0];
		if (file) {
			if (this.checkFileType(file, this.SUPPORTED_IMG_FILES)) {
				this.uploadImgFile(file);
				return;
			}
			this.toastNotification.sendErrorToast('Unsupported file type found!');
		}
	}

	public uploadImgFile(file: File): void {
		this.lollyLoadingDialogService.showLoader();
		this.sharedService
			.uploadFile(file)
			.pipe(take(1))
			.subscribe(
				res => {
					this.lollyLoadingDialogService.dismissLoader();
					this.userForm.get('company_logo').patchValue(res?.data);
				},
				err => {
					this.lollyLoadingDialogService.dismissLoader();
					this.toastNotification.sendErrorToast('Something went wrong. Try again later.');
				}
			);
	}

	public checkFileType(file: File, types: string[]): boolean {
		const fileType = file.type;
		return types.some(type => {
			return fileType === type;
		});
	}
}
