<!-- 
  * * * THIS COMPONENT IS A CUSTOM IMPLEMENTATION OF MAT EXPANSION AS TABLE * * *
-->
<div class="generic-table-wrapper">
	<mat-accordion [multi]="isMulti">
		<!-- * Table Header -->
		<mat-expansion-panel disabled hideToggle="true" *ngIf="showTableHeader">
			<mat-expansion-panel-header class="expansion-table-header">
				<mat-panel-title>
					<div class="table-header">
						<ng-container *ngFor="let column of columns">
							<div
								class="table-column header"
								*ngIf="column.type == GenericTableColumnTypeEnum.TEXT || column.type == GenericTableColumnTypeEnum.MENU"
								[ngStyle]="column?.columnStyles"
								(click)="onColumnClick(column)"
							>
								<div
									class="icon-container"
									*ngIf="column?.expandCollapseIconVisibility"
									[ngClass]="{
										'd-none': column?.expandCollapseIconVisibility == ExpandCollapseIconVisibilityEnum.HIDE,
										'not-visible': column?.expandCollapseIconVisibility == ExpandCollapseIconVisibilityEnum.INVISIBLE
									}"
								>
									<mat-icon>
										add
									</mat-icon>
								</div>

								<div class="title">
									{{ column?.title }}
								</div>

								<div class="tooltip-container" *ngIf="column?.tooltipData?.showTooltip">
									<app-i-grey-tooltip [text]="column?.tooltipData?.tooltipText"></app-i-grey-tooltip>
								</div>

								<div
									class="suffix-icon-container cursor-pointer"
									[ngStyle]="column?.suffixContainerStyles"
									*ngIf="column?.suffixIcon"
									(click)="onColumnSuffix(column)"
								>
									<img [ngStyle]="column?.suffixIconStyles" [src]="column?.suffixIcon" />
								</div>

								<ng-container *ngIf="column?.sortAndFilterMenu">
									<mat-icon class="filter-icon" [matMenuTriggerFor]="filterSortMenu" #filterSortMenuTrigger="matMenuTrigger">
										filter_list
									</mat-icon>
									<mat-menu #filterSortMenu="matMenu" class="filter-sort-menu">
										<div class="h-100 d-flex flex-column" (click)="$event.stopPropagation()">
											<div class="menu-title">{{ column?.sortAndFilterMenu?.primaryTitle }}</div>
											<div class="menu-body">
												<div class="d-flex flex-column" *ngIf="!column?.sortAndFilterMenu?.hideSortOptions">
													<div
														(click)="
															$event.stopPropagation();
															selectSortOption(SortByOptionsEnum.ASC, column?.sortAndFilterMenu);
															filterSortMenuTrigger.closeMenu()
														"
														class="filter-menu-list-item v-pad {{
															column?.sortAndFilterMenu?.sortBy == SortByOptionsEnum.ASC ? 'dark' : ''
														}}"
													>
														<mat-icon>filter_list</mat-icon>
														<span class="filter-drp-name w-100">
															Sort A → Z
														</span>
													</div>

													<div
														(click)="
															$event.stopPropagation();
															selectSortOption(SortByOptionsEnum.DSC, column?.sortAndFilterMenu);
															filterSortMenuTrigger.closeMenu()
														"
														class="filter-menu-list-item v-pad {{
															column?.sortAndFilterMenu?.sortBy == SortByOptionsEnum.DSC ? 'dark' : ''
														}}"
													>
														<mat-icon>filter_list</mat-icon>
														<span class="filter-drp-name w-100">
															Sort Z → A
														</span>
													</div>
												</div>

												<div
													class="d-flex flex-column"
													*ngIf="!column?.sortAndFilterMenu?.hideFilterOptions && !column?.sortAndFilterMenu?.hideFilterSearch"
												>
													<div class="menu-title">{{ column?.sortAndFilterMenu?.secondaryTitle }}</div>
													<div class="grey-search">
														<img src="assets/discovery-v2/search-grey.svg" />
														<input
															type="text"
															placeholder="Search"
															(input)="onFilterSearchValueChange($event.target.value, column?.sortAndFilterMenu)"
														/>
													</div>
												</div>

												<div
													class="d-flex flex-column"
													*ngIf="!column?.sortAndFilterMenu?.hideFilterOptions"
													style="padding-top: 12px;"
												>
													<div
														(click)="$event.stopPropagation()"
														*ngFor="let filterMenuItem of column?.sortAndFilterMenu?.filteredList; let filterSequence = index"
														class="filter-menu-list-item"
													>
														<mat-checkbox
															class="custom-checkbox-black w-100"
															*ngIf="filterMenuItem?.selected != undefined || filterMenuItem?.selected != null"
															type="checkbox"
															[(ngModel)]="filterMenuItem.selected"
															(ngModelChange)="toggleFilterSelection(filterMenuItem)"
														>
															<span class="filter-drp-name w-100">
																{{ filterMenuItem?.name }}
															</span>
														</mat-checkbox>
													</div>
												</div>
											</div>
											<div class="menu-footer">
												<button class="primary-btn" (click)="applyFilters(column?.sortAndFilterMenu)">Show</button>
												<button class="quaternary-btn" (click)="clearFilters(column?.sortAndFilterMenu)">Clear</button>
											</div>
										</div>
									</mat-menu>
								</ng-container>
							</div>

							<div
								class="table-column header"
								*ngIf="column.type == GenericTableColumnTypeEnum.CHECKBOX"
								[ngStyle]="column?.columnStyles"
								(click)="onColumnClick(column)"
							>
								<!-- <mat-checkbox
									[indeterminate]="column?.isCheckboxIndeterminate"
									[disabled]="column?.isCheckboxDisabled"
									[checked]="column?.isCheckboxChecked"
									class="custom-checkbox-black-shorter"
									(change)="onColumnCheckboxChange($event, column)"
								></mat-checkbox> -->
								<mat-checkbox
									[indeterminate]="column?.isCheckboxIndeterminate"
									[disabled]="column?.isCheckboxDisabled"
									[checked]="column?.isCheckboxChecked"
									class="custom-checkbox-black-shorter"
									(click)="$event.preventDefault(); onColumnCheckboxChange($event, column)"
								></mat-checkbox>
							</div>
						</ng-container>
					</div>
				</mat-panel-title>
			</mat-expansion-panel-header>
		</mat-expansion-panel>

		<mat-expansion-panel
			*ngFor="let row of rows; let rowSequence = index"
			id="{{ row?.id }}"
			[expanded]="row?.isExpanded"
			(opened)="onMatExpansionOpen(row)"
			(closed)="onMatExpansionClosed(row)"
			hideToggle="true"
			[disabled]="row?.disableExpand || !row?.children?.length"
		>
			<mat-expansion-panel-header class="expansion-table-body" [style.height]="rowHeight">
				<mat-panel-title>
					<div class="table-rows-wrapper">
						<div class="table-row" [ngStyle]="row?.rowStyles">
							<ng-container *ngFor="let dataRow of row?.values; let dataRowSequence = index">
								<!-- * TEMPLATE FOR CHECKBOX TYPE DATASET -->
								<div class="table-column" [ngStyle]="dataRow?.datasetStyles" *ngIf="dataRow.type == GenericTableDatasetTypeEnum.CHECKBOX">
									<!-- <mat-checkbox
										[indeterminate]="dataRow?.isCheckboxIndeterminate"
										[disabled]="dataRow?.isCheckboxDisabled"
										[checked]="dataRow?.isCheckboxChecked"
										class="custom-checkbox-black-shorter"
										(click)="onDatasetCheckboxChange($event, dataRow)"
									></mat-checkbox> -->
									<mat-checkbox
										[indeterminate]="dataRow?.isCheckboxIndeterminate"
										[disabled]="dataRow?.isCheckboxDisabled"
										[checked]="dataRow?.isCheckboxChecked"
										class="custom-checkbox-black-shorter"
										(click)="$event.preventDefault(); onDatasetCheckboxChange($event, dataRow)"
									></mat-checkbox>
								</div>

								<!-- * TEMPLATE FOR PLATFORMS TYPE DATASET -->
								<div class="table-column" [ngStyle]="dataRow?.datasetStyles" *ngIf="dataRow.type == GenericTableDatasetTypeEnum.PLATFORMS">
									<ng-container *ngFor="let platform of dataRow.platforms">
										<img
											class="platform-icon"
											[ngStyle]="dataRow?.platformIconStyle"
											[src]="'assets/icons/social-media-icons/' + platform + '.svg'"
										/>
									</ng-container>
								</div>

								<!-- * TEMPLATE FOR CREATOR PROFILE TYPE DATASET -->
								<div
									class="table-column"
									[ngStyle]="dataRow?.datasetStyles"
									*ngIf="dataRow.type == GenericTableDatasetTypeEnum.CREATOR_PROFILE"
								>
									<div class="creator-profile-wrapper" (click)="onDataRowTitleClick(dataRow)">
										<img class="creator-pp" [src]="dataRow?.profileData?.image" />

										<div class="name">
											{{ dataRow?.profileData?.name }}
										</div>

										<div class="symbols-wrapper" *ngIf="dataRow?.profileData?.verifiedSymbols?.length">
											<ng-container *ngFor="let platform of dataRow?.profileData?.verifiedSymbols">
												<ng-container *ngIf="platform != 'lolly'">
													<img
														[ngStyle]="dataRow?.profileData?.verifiedSymbolStyles"
														[src]="'assets/discovery-v2/' + platform + '-verified.svg'"
													/>
												</ng-container>
												<ng-container *ngIf="platform == 'lolly'">
													<div class="lolly-verified-symbol" matTooltip="Lolly Verified">
														<mat-icon>check</mat-icon>
													</div>
												</ng-container>
											</ng-container>
										</div>
									</div>
								</div>

								<!-- * TEMPLATE FOR TEXT TYPE DATASET -->
								<div
									class="table-column"
									(click)="onDatasetClick(dataRow)"
									[ngStyle]="dataRow?.datasetStyles"
									*ngIf="dataRow.type == GenericTableDatasetTypeEnum.TEXT"
								>
									<div
										class="icon-container cursor-pointer"
										*ngIf="dataRow?.expandCollapseIconVisibility"
										[ngClass]="{
											'd-none': dataRow?.expandCollapseIconVisibility == ExpandCollapseIconVisibilityEnum.HIDE,
											'not-visible': dataRow?.expandCollapseIconVisibility == ExpandCollapseIconVisibilityEnum.INVISIBLE
										}"
									>
										<mat-icon>
											{{ row?.isExpanded ? 'remove' : 'add' }}
										</mat-icon>
									</div>

									<div
										*ngIf="dataRow?.prefixIcon"
										class="prefix-icon-container cursor-pointer"
										[ngStyle]="dataRow?.prefixContainerStyles"
										(click)="$event.stopPropagation(); onDataRowPrefix(dataRow)"
									>
										<img [ngStyle]="dataRow?.prefixIconStyles" [src]="dataRow?.prefixIcon" />
									</div>

									<div class="title" [ngStyle]="dataRow?.titleStyles" (click)="onDataRowTitleClick(dataRow)">
										{{ dataRow?.title }}
									</div>

									<div
										class="suffix-icons-container cursor-pointer {{ dataRow?.suffixIconsData?.showOnlyOnHover ? 'only-on-hover' : '' }}"
										[ngStyle]="dataRow?.suffixIconsData?.suffixIconContainerStyles"
										*ngIf="dataRow?.suffixIconsData"
									>
										<ng-container *ngFor="let icon of dataRow?.suffixIconsData.suffixIconList">
											<ng-container *ngIf="icon.suffixType == IconSourceTypeEnum.IMAGE">
												<img
													[src]="icon.suffixIcon"
													[ngStyle]="icon.suffixIconStyles"
													(click)="$event.stopPropagation(); onDataRowSuffixClick(icon)"
												/>
											</ng-container>

											<ng-container *ngIf="icon.suffixType == IconSourceTypeEnum.MAT_ICON">
												<mat-icon [ngStyle]="icon.suffixIconStyles" (click)="$event.stopPropagation(); onDataRowSuffixClick(icon)">
													{{ icon.suffixIcon }}
												</mat-icon>
											</ng-container>
										</ng-container>
									</div>
								</div>

								<!-- * TEMPLATE FOR MENU TYPE DATASET -->
								<div class="table-column" [ngStyle]="dataRow?.datasetStyles" *ngIf="dataRow.type == GenericTableDatasetTypeEnum.MENU">
									<mat-icon class="cursor-pointer" [matMenuTriggerFor]="optionsMenu" #optionsMenuTrigger="matMenuTrigger">more_vert</mat-icon>
									<mat-menu #optionsMenu="matMenu" class="options-menu">
										<div
											(click)="$event.stopPropagation(); onSelectMenuOption(option, dataRow); optionsMenuTrigger.closeMenu()"
											*ngFor="let option of dataRow.menuOptions; let optionSequence = index"
											class="filter-menu-list-item"
										>
											<img *ngIf="option?.image" [src]="option.image" />
											<span class="filter-drp-name w-100">
												{{ option.name }}
											</span>
										</div>
									</mat-menu>
								</div>

								<!-- * TEMPLATE FOR UNLOCK EMAIL TYPE DATASET -->
								<div
									class="table-column"
									(click)="onDatasetClick(dataRow)"
									[ngStyle]="dataRow?.datasetStyles"
									*ngIf="dataRow.type == GenericTableDatasetTypeEnum.UNLOCK_EMAIL"
								>
									<div class="email-wrapper">
										<ng-container *ngIf="dataRow?.unlockEmailData?.unlocked">
											<div class="email-unlocked {{ dataRow?.unlockEmailData?.unlockLoading ? 'black-back' : '' }}">
												<span *ngIf="dataRow?.unlockEmailData?.unlockLoading" class="load-span">
													<img src="assets\discovery-v2\profile\spinner.gif" alt="" class="loader" />
												</span>
												<span *ngIf="!dataRow?.unlockEmailData?.unlockLoading">
													{{
														dataRow?.unlockEmailData?.email && dataRow?.unlockEmailData?.email != ''
															? dataRow?.unlockEmailData?.email
															: "Email doesn't exist"
													}}
												</span>
												<ng-container *ngIf="!dataRow?.unlockEmailData?.unlockLoading">
													<img
														*ngIf="!dataRow?.unlockEmailData?.copied"
														class="{{ dataRow?.unlockEmailData?.email && dataRow?.unlockEmailData?.email != '' ? '' : 'disabled' }}"
														(click)="copyToClipboard(dataRow?.unlockEmailData)"
														src="assets/discovery-v2/copy.svg"
													/>
													<img
														*ngIf="dataRow?.unlockEmailData?.copied"
														class="{{ dataRow?.unlockEmailData?.email && dataRow?.unlockEmailData?.email != '' ? '' : 'disabled' }}"
														(click)="copyToClipboard(dataRow?.unlockEmailData)"
														src="assets/discovery-v2/copy-active.svg"
													/>
												</ng-container>
											</div>
										</ng-container>

										<ng-container *ngIf="!dataRow?.unlockEmailData?.unlocked">
											<button class="secondary-btn" (click)="onUnlockEmailClick(dataRow?.unlockEmailData)">
												Unlock email
											</button>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</mat-panel-title>
			</mat-expansion-panel-header>

			<div class="table-body-wrapper" *ngIf="row?.children?.length">
				<app-generic-table [showTableHeader]="false" [rows]="row?.children"></app-generic-table>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
