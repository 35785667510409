import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { BYPASS_LOG } from 'src/app/_services/interceptors/http-config.interceptor';
import { campaignCurrency } from 'src/app/shared-components/models/discovery/campaign-form.interface';
import { DiscoveryListModel } from 'src/app/shared-components/models/discovery/discovery-list.model';
import { CreateListResponseModel } from 'src/app/shared-components/models/discovery/discovery-uploaded-list-data.model';
import { StripeAccountDataModel } from '../models/stripe-account.model';
import { FirstTimerUserModel, GetStartedDataModel, IGetStartedDataResponseModel, TrialDataModel } from '../models/first-timer-user.model';
import { UserPlanRestrictionModel } from '../models/user-plan-restriction.model';
import { PaymentStatusModel } from 'src/app/shared-components/models/payment-status.model';

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	// by default keep null
	public discoveryCreatorIds? = [];
	public discoveryListId = -1;
	public discoverySendJobTo = '';
	public discoveryCampaignName? = '';
	public getStartedData$: BehaviorSubject<GetStartedDataModel> = new BehaviorSubject<GetStartedDataModel>({});
	public firstTimerData$: BehaviorSubject<FirstTimerUserModel> = new BehaviorSubject<FirstTimerUserModel>({});
	public trialPeriodData$: BehaviorSubject<TrialDataModel> = new BehaviorSubject<TrialDataModel>({});
	public triggerFirstTimeGetStarted: Subject<void> = new Subject();
	public sidenavVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	public userPlanRestrictions$: BehaviorSubject<UserPlanRestrictionModel> = new BehaviorSubject<UserPlanRestrictionModel>({
		advanced_search: true,
		campaign_analytics: true,
		payouts: true
	});

	constructor(private http: HttpClient) {}

	public getCuratedCurrencyList(): Observable<{ data: campaignCurrency[] }> {
		return this.http.get<{ data: campaignCurrency[] }>(`${BaseApiUrl.SocialMessengerPython}currency/available`);
	}

	// ! First time user apis
	public disableFirstTimeValues(payload): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}users/first-time`);
		return this.http.patch<any>(url.href, payload);
	}

	// ! Get Started Data for user api
	public getStartedData(): Observable<IGetStartedDataResponseModel> {
		return this.http.get<IGetStartedDataResponseModel>(`${BaseApiUrl.SocialMessengerPython}users/get-started`);
	}

	public getAllLists(queryParams: HttpParams): Observable<{ data: DiscoveryListModel[]; pagination: any; is_allow_create: boolean }> {
		let urlString = `${BaseApiUrl.SocialMessengerPython}lists`;
		const url = new URL(urlString);

		return this.http.get<{ data: DiscoveryListModel[]; pagination: any; is_allow_create: boolean }>(url.href, { params: queryParams });
	}

	public addListToCampaign(payload): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}applications/lists-to-campaign`;
		return this.http.post<any>(url, payload);
	}

	public addCreatorToExistingLists(listIds: number[], influencerIds: (string | number)[], bulkSelectCount?: number, filterString?: string): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}lists/creators`;
		let postBody = { list_ids: listIds, discovery_ids: influencerIds };

		if (bulkSelectCount) {
			postBody['size'] = bulkSelectCount;
		}
		if (filterString) {
			postBody['filter'] = filterString;
		}

		return this.http.post<any>(url, postBody);
	}

	public sendJobToMultipleLists(listIds: number[], jobIds: number[]): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}applications/batch`;
		let postBody = { list_ids: listIds, job_ids: jobIds };

		return this.http.post<any>(url, postBody);
	}

	public sendCampaignToMultipleLists(listIds: number[], campaignId: number): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}applications`;
		let postBody = { list_ids: listIds, campaign_id: campaignId, type: 'brand-bulk' };

		return this.http.post<any>(url, postBody);
	}

	public createEmptyList(name: string): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}lists`;
		let postBody = { name: name };

		return this.http.post<any>(url, postBody);
	}

	public getAllTimezones(): Observable<{ timezones: string[] }> {
		const url = `${BaseApiUrl.SocialMessengerPython}utils/timezones`;
		return this.http.get<{ timezones: string[] }>(url);
	}

	public uploadImageTemplateAttachment(data: FormData): Observable<Object> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}templates/image`);
		return this.http.put(url.href, data);
	}

	public uploadFileTemplateAttachment(data: FormData): Observable<Object> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}templates/attachments`);
		return this.http.put(url.href, data);
	}

	public uploadFile(file: File): Observable<any> {
		const body = new FormData();
		body.append('file', file);
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}upload/file`, body);
	}

	public getClientIp(): Observable<any> {
		return this.http.get(`https://api.ipify.org/?format=json`, { context: new HttpContext().set(BYPASS_LOG, true) });
	}

	public getUserLocationAndCurrency(ip: string): Observable<any> {
		const url = `${BaseApiUrl.SocialMessengerPython}users/country-details?ip=${ip}`;
		return this.http.get<any>(url);
	}

	// ! MY PLANS APIS
	public getPlanDetails(): Observable<any> {
		return this.http.get(`${BaseApiUrl.SocialMessengerPython}users/credit-usage`);
	}

	public cancelPlan(payload): Observable<any> {
		return this.http.post(`${BaseApiUrl.SocialMessengerPython}subscriptions/cancel-plan`, payload);
	}

	// ! First time user apis
	public disableFirstTimeDiscoveryListsOnPlatform(): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialInfluencerPython}users/end-discovery-list`);
		return this.http.put<any>(url.href, {});
	}

	// ! Callback API for google
	public googleSignUpApi(code: string): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}oauth/crm_gmail/callback?code=${code}`);
		return this.http.get<any>(url.href);
	}

	public userPlanInfo(): Observable<UserPlanRestrictionModel> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}subscriptions/restrictions`);
		return this.http.get<UserPlanRestrictionModel>(url.href);
	}

	public sendTestEmail(payload: any): Observable<any> {
		const url = new URL(`${BaseApiUrl.SocialMessengerPython}templates/test_template`);
		return this.http.post<any>(url.href, payload);
	}

	// ! GET USER PROFILE
	public getProfileDetails(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}users/view-profile`);
	}

	// ! CONNECT STRIPE
	public connectStripeAccount(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}oauth/stripe/preinstall`);
	}

	public disconnectPaymentAccount(platform: string): Observable<any> {
		return this.http.delete<any>(`${BaseApiUrl.SocialMessengerPython}payments/brand?platform=${platform}`);
	}

	// ! GET STRIPE ACCOUNT DATA
	public getPayoutAccountData(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}payments/brand`);
	}

	// ! GET PAYMENTN STATUS
	public getUserPaymentStatus(): Observable<PaymentStatusModel> {
		return this.http.get<PaymentStatusModel>(`${BaseApiUrl.SocialMessengerPython}users/generate_link_by_payment_id`);
	}

	public connectPaypalAccount(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.SocialMessengerPython}oauth/paypal/preinstall`);
	}

	public savePaypalInfo(paypalInfo): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.SocialMessengerPython}oauth/paypal/save`, paypalInfo);
	}
}
