import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AgGridModule } from 'ag-grid-angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { from, Observable } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AccountTypeService } from './shared/account-type.service';
import { CaseInsensitiveUrlSerializer } from './shared/case-insensitive-url-serializer.service';
import { ComponentRegistryService } from './shared/component-registry.service';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { UserRoleAdminGuard } from './shared/guards/user-role-admin-guard.service';
import { MapperService } from './shared/services/mapper.service';
import { SharedModule } from './shared/shared.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { clearStateMetaReducer, initialAppState, reducers, REDUCER_TOKEN } from './state/app.state';
import { AuthenticationService } from './_services/authentication.service';
import { DashboardService } from './_services/dashboard/dashboard-service';
import { ErrorsLoggingServiceApi } from './_services/errors-logging/errors-logging-api.service';
import { ErrorsLoggingService } from './_services/errors-logging/errors-logging.service';
import { AdAccountApiService } from './_services/facebook-accounts/ad-account-api.service';
import { BusinessOwnerService } from './_services/facebook-accounts/business-owner.service';
import { InstagramService } from './_services/facebook-accounts/instagram.service';
import { PageService } from './_services/facebook-accounts/page.service';
import { AdvertService } from './_services/facebook-marketing/advert.service';
import { GoogleService } from './_services/google/google.service';
import { InterceptorsProvider } from './_services/interceptors/intercepors.provider';
import { OptimizationService } from './_services/optimization/optimization.service';
import { PopupDialogService } from './_services/popup-dialog.service';
import { SidenavService } from './_services/side-nav.service';
import { TableService } from './_services/table.service';
import { UserServiceSubscription } from './_services/user/user-subscription.service';
import { UserServiceApi } from './_services/user/user.api.service';

import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { MaterialModule } from './material.module';
import { AuthenticationRouteGuard } from './shared/guards/authentication-route.guard';
import { UserBlockGuard } from './shared/guards/user-block.guard';
import { UserBusinessOwnerGuard } from './shared/guards/user-business-owner.guard';
import { StripeLandingComponent } from './stripe-landing/stripe-landing.component';
import { AppCustomPreloader } from './app-routing-preloader';
import { QUILL_CONFIG_TOKEN, QuillModule } from 'ngx-quill';
import { ShopifyLandingComponent } from './shopify-landing/shopify-landing.component';
import { HammerModule } from '../../node_modules/@angular/platform-browser';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BigcommerceInstallationGuideComponent } from './bigcommerce-installation-guide/bigcommerce-installation-guide.component';

export class WebpackTranslateLoader implements TranslateLoader {
	public getTranslation(lang: string): Observable<any> {
		return from(import(`../assets/i18n/${lang}.json`));
	}
}

@NgModule({
	declarations: [AppComponent, StripeLandingComponent, ShopifyLandingComponent, BigcommerceInstallationGuideComponent],
	exports: [],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		StoreModule.forRoot(REDUCER_TOKEN, {
			metaReducers: [clearStateMetaReducer],
			initialState: initialAppState,
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false
			}
		}),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			name: 'Filed',
			maxAge: 50
		}),
		AgGridModule.withComponents([]),
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		SharedModule,
		SidenavModule,
		AuthenticationModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
		InputsModule,
		LabelModule,
		DropDownsModule,
		NotificationModule,
		LayoutModule,
		TooltipModule,
		DialogsModule,
		DateInputsModule,
		EditorModule,
		GridModule,
		MaterialModule,
		MatDialogModule,
		QuillModule.forRoot(),
		NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
		HammerModule,
		NgxDaterangepickerMd.forRoot()
	],
	providers: [
		{
			provide: REDUCER_TOKEN,
			useValue: reducers
		},
		{
			provide: MatDialogRef,
			useValue: {}
		},
		{ provide: MAT_DIALOG_DATA, useValue: {} },
		AppCustomPreloader,
		ErrorsLoggingService,
		ErrorsLoggingServiceApi,
		MapperService,
		AuthenticationService,
		UserServiceApi,
		UserServiceSubscription,
		SidenavService,
		TableService,
		OptimizationService,
		AdvertService,
		PageService,
		InstagramService,
		BusinessOwnerService,
		AdAccountApiService,
		AuthenticationGuard,
		UserRoleAdminGuard,
		InterceptorsProvider,
		PopupDialogService,
		DashboardService,
		GoogleService,
		AccountTypeService,
		UserBusinessOwnerGuard,
		AuthenticationRouteGuard,
		UserBlockGuard,

		ComponentRegistryService,
		{
			provide: UrlSerializer,
			useClass: CaseInsensitiveUrlSerializer
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
