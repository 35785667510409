import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationState } from '../../state/authentication.reducer';
import { Store } from '@ngrx/store';
import { LogInSuccess, RegisterUserEmail, RegisterUserLastName, RegisterUserName, RegisterUserPassword } from '../../state/authentication.action';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { sleep } from '../utils/signup-utils';
import { UserDetailModel } from '../../_models/login.model';
import { newSignUpInterface, NewSignUpSubscribe } from '../../sign-up/subscribe.interface';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { environment } from 'src/environments/environment';
import { GoogleService } from 'src/app/_services/google/google.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { AuthPaymentService } from '../../services/auth-payment.service';
import { CustomValidator } from 'src/app/shared/form-input/input-password/custom-validator';
import { UtilsService } from 'src/app/shared/utils';

declare var google: any;
declare function gtag_report_conversion(url?: string): void;

@Component({
	selector: 'app-email-register',
	templateUrl: './email-register-new.component.html',
	styleUrls: ['./email-register-new.component.scss']
})
export class EmailRegisterComponent implements OnInit, AfterViewInit {
	public errorMessage: string;
	public emControl: UntypedFormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;
	private googleCientId = environment.Google.ClientId;
	public productId: string;
	public credits: number;
	public allComplete: boolean = false;
	public showPassword: boolean = false;
	public showConfirmPassword: boolean = false;
	// @ViewChild('googleBtn') googleBtn;

	public firstNameFormControl = new UntypedFormControl('', [
		Validators.required,
		Validators.minLength(2),
		Validators.maxLength(50),
		Validators.pattern(/^[A-Za-z]+$/)
	]);

	public lastNameFormControl = new UntypedFormControl('', [
		Validators.required,
		Validators.minLength(2),
		Validators.maxLength(50),
		Validators.pattern(/^[A-Za-z]+$/)
	]);

	public cmControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(50), UtilsService.noWhitespaceAtStartValidator]);

	public passFormControl = new UntypedFormControl('', [
		Validators.maxLength(50),
		Validators.required,
		Validators.minLength(8),
		CustomValidator.patternValidator(/[0-9]/, {
			hasNumber: true
		}),
		CustomValidator.patternValidator(/[a-z]/, {
			hasSmallCase: true
		}),
		CustomValidator.patternValidator(/[A-Z]/, {
			hasCapitalCase: true
		}),
		CustomValidator.patternValidator(/^(?=.*[A-Z])(?=.*[\W_]).*$/, {
			hasSpecialCharacters: true
		})
	]);
	// public confirmpassFormControl = new UntypedFormControl('', [Validators.required]);
	public registrationDetais: UserDetailModel;
	public planId: number;
	public utmSource: string;
	public utmMedium: string;
	public utmCampaign: string;
	public without_cc: string = 'false';
	public subscribeData: NewSignUpSubscribe;
	public error: boolean;
	public emailSent: boolean;
	public showConfirmPasswordError = null;
	public hidePasswordError = null;
	public formGroup: UntypedFormGroup;
	public firstNameValidationIcon = null;
	public lastNameValidationIcon = null;
	public emailValidationIcon = null;
	public passwordValidationIcon = null;
	public confirmPasswordValidationIcon = null;
	public priceId: string = '';
	public shpfyact: string = '';
	public loading: boolean = false;
	public skipPricing = false;

	constructor(
		private router: Router,
		private store: Store<AuthenticationState>,
		private toastNotificationService: ToastNotificationService,
		private sharedStore: Store<SharedState>,
		private activatedRoute: ActivatedRoute,
		private backOfficeService: BackOfficeService,
		private ngZone: NgZone,
		private formBuilder: FormBuilder,
		private googleService: GoogleService,
		private authPaymentService: AuthPaymentService
	) {}

	public ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe(params => {
			if (params['shpfyact']) {
				this.shpfyact = params['shpfyact'];
			}

			if (params['utm_source']) {
				this.utmSource = params['utm_source'];
			}

			if (params['utm_medium']) {
				this.utmMedium = params['utm_medium'];
			}

			if (params['utm_campaign']) {
				this.utmCampaign = params['utm_campaign'];
			}

			if (params['without_cc']) {
				this.without_cc = params['without_cc'] ?? 'false';
			}

			this.priceId = params['price_id'];

			// if price exist in query skip pricing page
			if (this.priceId) this.skipPricing = true;

			if (!this.priceId) {
				this.priceId = environment.DEFAULT_PRICE_ID;
			}
		});
		const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
		this.emControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(70), Validators.pattern(emailValidator)]);
		this.groupForm();
		this.onFormNameChange();
		this.onFormLastNameChange();
		this.onFormEmailChange();
		this.onFormPasswrordChange();
		// this.onFormConfirmPasswordChange();
		// console.log(location.pathname, location.href);
		// console.log(this.firstNameFormControl);
	}

	public groupForm(): void {
		this.formGroup = this.formBuilder.group({
			firstNameFormControl: this.firstNameFormControl,
			lastNameFormControl: this.lastNameFormControl,
			passFormControl: this.passFormControl,
			// confirmpassFormControl: this.confirmpassFormControl,
			emControl: this.emControl,
			cmControl: this.cmControl
		});
	}

	public formChanges(): void {
		this.emControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
	}

	public onFormNameChange(): void {
		this.firstNameFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.firstNameFormControl.valid) {
				this.store.dispatch(new RegisterUserName(this.firstNameFormControl.value));
			}
		});
	}

	public onFormLastNameChange(): void {
		this.lastNameFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.lastNameFormControl.valid) {
				this.store.dispatch(new RegisterUserLastName(this.lastNameFormControl.value));
			}
		});
	}

	public onFormEmailChange(): void {
		this.emControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.emControl.valid) {
				this.store.dispatch(new RegisterUserEmail(this.emControl.value));
			}
		});
	}

	public onFormPasswrordChange(): void {
		this.passFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.passFormControl.valid) {
				this.store.dispatch(new RegisterUserPassword(this.passFormControl.value));
			}
		});
	}

	// public onFormConfirmPasswordChange(): void {
	// 	this.confirmpassFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
	// 		if (!this.confirmpassFormControl.valid || value !== this.passFormControl.value) {
	// 			this.confirmpassFormControl.setErrors({ NoPasswordMatch: true });
	// 		}
	// 	});
	// }

	public checkForInvalid(): void {
		const email = this.emControl.value;
		if (!this.emControl) {
			this.emControl.setErrors({ dialCode: true });
		}
	}

	public sleep(ms: number): Promise<any> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	public backToLogin(): void {
		if (this.shpfyact == '') {
			location.href = '/authentication';
		} else {
			location.href = `/authentication?shpfyact=${this.shpfyact}`;
		}
	}

	public continueSignupTemp(): void {
		this.submitting = true;
		sleep(3000);
		this.router.navigate(['/authentication/verify-email']);
		this.submitting = false;
	}

	// private decodeToken(token: string): string {
	// 	return jwt_decode(token);
	// }

	public processRegistrationDetail(): void {
		if (!this.formFieldsValid()) {
			return;
		}

		this.submitting = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());

		this.error = false;

		let newPayload: newSignUpInterface = {
			first_name: this.firstNameFormControl.value,
			last_name: this.lastNameFormControl.value,
			company: this.cmControl.value,
			phone_number: '',
			password: this.passFormControl.value,
			username: this.emControl.value?.trim()?.toLowerCase(),
			// price_id: this.priceId,
			shpfyact: this.shpfyact,
			utm_source: this.utmSource,
			utm_medium: this.utmMedium,
			utm_campaign: this.utmCampaign,
			is_without_card: this.without_cc === 'true' ? true : false,
			...(this.skipPricing ? { payment_version: 'app_v2', price_id: this.priceId } : {})
		};
		this.loading = true;
		this.backOfficeService
			.registerNewUser(newPayload)
			.toPromise()
			.catch(error => {
				this.loading = false;
				this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
				this.submitting = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
				return;
			})
			.then((res: any) => {
				this.loading = false;
				this.submitting = false;
				this.sharedStore.dispatch(new HideGlobalSpinner());
				// if (typeof res === 'string') {
				// 	this.emailSent = true;
				// 	this.toastNotificationService.sendSuccessToast('Email was successfully sent.');
				// 	this.router.navigate(['/authentication/verify-email'], { state: { token: res, priceId: this.priceId } });
				// 	window['lpr']('referral', { email: this.emControl.value });
				// } else {
				// 	this.toastNotificationService.sendErrorToast(res?.message);
				// }

				this.signupSuccessCallback(res, 'password');
			});
	}

	public signUpWithGoogle(token): void {
		this.submitting = true;
		this.loading = true;
		const payload = {
			token: token.credential,
			utm_source: this.utmSource,
			utm_medium: this.utmMedium,
			utm_campaign: this.utmCampaign,
			is_without_card: this.without_cc === 'true' ? true : false,
			...(this.skipPricing ? { payment_version: 'app_v2', price_id: this.priceId } : {})
		};
		if (token) {
			this.googleService
				.googleSignup(payload)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					res => {
						this.submitting = false;
						// if (typeof res === 'string') {
						// 	this.ngZone.run(() => {
						// 		this.router.navigate(['/authentication/payment'], { queryParams: { token: res, price_id: this.priceId } });
						// 	});
						// }
						// else if (res.message) {
						// 	this.toastNotificationService.sendErrorToast(res.message);
						// }
						// else {
						// 	this.toastNotificationService.sendErrorToast('Error Signing you In, Please try later');
						// }
						this.ngZone.run(() => {
							this.signupSuccessCallback(res, 'google');
						});
					},
					err => {
						this.submitting = false;
						if (err.message) {
							this.toastNotificationService.sendErrorToast(err.message);
						} else {
							this.toastNotificationService.sendErrorToast('Error Signing you In, Please try later');
						}
						this.loading = false;
					},
					() => {
						this.loading = false;
					}
				);
		}
	}

	private signupSuccessCallback(res, type: string): void {
		if (res.status == false) {
			this.toastNotificationService.sendErrorToast(res.message);
			// return;
		} else {
			const { token, paymentLink } = res;
			localStorage.setItem(StorageKey.token, token);
			window['lpr']('referral', { email: this.emControl.value });

			if (this.without_cc === 'false') {
				if (this.skipPricing) {
					window.open(paymentLink, '_self');
				} else {
					this.router.navigate(['/authentication/payment'], { queryParams: { token, price_id: this.priceId } });
				}
			} else {
				gtag_report_conversion();
				if (type == 'google') {
					this.router.navigate(['google-landing'], { state: { token: token }, queryParamsHandling: 'merge' });
					return;
				}
				this.toastNotificationService.sendSuccessToast('Signed up successfully. A verification link has been sent to your email.', 7000);
				this.router.navigate(['/authentication/verification'], {
					state: { email: this.emControl.value?.trim()?.toLowerCase() },
					queryParamsHandling: 'merge'
				});
			}
		}
	}

	// public redirectToStripe(key): void {
	// 	const token = localStorage.getItem(StorageKey.token);

	// 	this.authPaymentService
	// 		.redirectToStripe(token, key)
	// 		.subscribe(res => {
	// 			// console.log(res);
	// 			window.open(res, '_blank');
	// 		})
	// 		.add(() => {});
	// }

	public formFieldsValid(): boolean {
		let validity = false;
		if (this.firstNameFormControl.valid && this.lastNameFormControl.valid && this.emControl.valid && this.passFormControl.valid) {
			validity = true;
		}
		return validity;
	}

	ngAfterViewInit(): void {
		google.accounts.id.initialize({
			client_id: this.googleCientId,
			callback: (response: any) => {
				this.signUpWithGoogle(response);
			},
			ux_mode: 'popup'
		});
		google.accounts.id.renderButton(
			document.getElementById('google-btn'),
			{ size: 'rectangular', width: '300px', height: '1px' } // customization attributes
		);
	}

	public goToTerms(event: any): void {
		window.open('https://www.lolly.com/terms/', '_blank');
		event.stopPropogation();
	}

	public goToPolicy(event: any): void {
		window.open('https://www.lolly.com/privacy/', '_blank');
		event.stopPropogation();
	}

	public getPasswordErrorText(): string {
		if (this.passFormControl.hasError('hasSmallCase')) {
			return 'Password must contain at least one lowercase character';
		}
		if (this.passFormControl.hasError('hasCapitalCase')) {
			return 'Password must contain at least one uppercase character';
		}
		if (this.passFormControl.hasError('hasNumber')) {
			return 'Password must contain at least one number';
		}
		if (this.passFormControl.hasError('hasSpecialCharacters')) {
			return 'Password must contain at least one special character';
		}
		if (this.passFormControl.hasError('minlength')) {
			return 'Password must be at least 8 characters long';
		}
	}
}
