<ng-container>
	<div class="action-button-2 cursor-pointer {{ disabled ? 'disabled' : '' }}">
		<mat-form-field appearance="outline" class="outline {{ disabled ? 'disabled-mat' : '' }}" [floatLabel]="'never'" (click)="onClickInput()">
			<mat-chip-list #chipListMat class="custom-chip-auto">
				<ng-container *ngIf="showInnerChips">
					<mat-chip *ngFor="let item of chipList; let i = index" (removed)="remove(item)" [disableRipple]="true">
						<div>
							{{ item }}
						</div>
						<div class="close" matChipRemove><mat-icon>close</mat-icon></div>
					</mat-chip>
				</ng-container>
				<input
					#multiChipInput
					matInput
					class="search-text-2"
					type="text"
					[placeholder]="placeholder"
					[formControl]="autocompleteControl"
					[matChipInputFor]="chipListMat"
					[matAutocomplete]="auto"
					(click)="$event.stopPropagation(); autoTrigger.openPanel()"
					#autoTrigger="matAutocompleteTrigger"
					[matChipInputSeparatorKeyCodes]="[]"
					[matChipInputAddOnBlur]="false"
					(matChipInputTokenEnd)="add($event)"
				/>
			</mat-chip-list>
			<mat-autocomplete class="auto-panel" #auto="matAutocomplete" (optionSelected)="selected($event)">
				<mat-option *ngFor="let item of filteredList" [value]="item[titleKey]">
					<div class="d-flex justify-content-between">
						<mat-checkbox class="custom-checkbox-black" type="checkbox" [checked]="chipList.includes(item[titleKey])" [labelPosition]="'before'">
							<span class="auto-search-drp-name w-100">
								{{ item[titleKey] }}
							</span>
						</mat-checkbox>
					</div>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
</ng-container>
